import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
const NewPaymentActivites = () => {
  const router = useRouter();
  const paymentActivities = [
    {
      icon: `${router.basePath}/assets/images/FeaturedIcon.webp`,
      image: `${router.basePath}/assets/images/Frame.webp`,
      title: 'Collect payments in HubSpot without switching tabs',
      description:
        'Receive payments within HubSpot CRM so you don’t need to open another app in your browser to check your payments.',
    },
    {
      icon: `${router.basePath}/assets/images/Activity-1.webp`,
      image: `${router.basePath}/assets/images/Group-2.webp`,
      title: 'Keep an eagle eye on your transactions',
      description:
        'Get detailed transaction and revenue reports in the app’s dashboard. Or build custom reports in HubSpot for better insights.',
    },
    {
      icon: `${router.basePath}/assets/images/Activity-2.webp`,
      image: `${router.basePath}/assets/images/Group-3.webp`,
      title: 'Run post payment campaigns on auto mode',
      description:
        'Send payment reminders, invoices, cart recovery emails on autopilot. Let your team be more productive, not just busy.',
    },
    {
      icon: `${router.basePath}/assets/images/Activity-3.webp`,
      image: `${router.basePath}/assets/images/Group-4.webp`,
      title: 'Build personalized payment-related campaigns',
      description:
        'Analyze your users’ behavior using payment properties to craft personalized campaigns to improve conversions and retention.',
    },
  ];
  const [topIndex, setTopIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const xVariants = {
    initial: (directionValue: any) => {
      return { x: directionValue > 0 ? 2 : -2, opacity: 0 };
    },
    animate: {
      x: 0,
      opacity: 1,

      transition: {
        x: {
          type: 'spring',
          stiffness: 300,
          damping: 30,
        },
        opacity: { duration: 0.2 },
      },
    },
    exit: (directionValue: any) => {
      return {
        x: directionValue > 0 ? -2 : 2,
        opacity: 0,

        transition: {
          x: {
            type: 'spring',
            stiffness: 300,
            damping: 30,
          },
          opacity: { duration: 0.2 },
        },
      };
    },
  };
  const variants = {
    initial: (directionValue: any) => {
      return { y: directionValue > 0 ? 200 : -200, opacity: 0 };
    },
    animate: {
      y: 0,
      opacity: 1,

      transition: {
        y: {
          type: 'spring',
          stiffness: 300,
          damping: 30,
        },
        opacity: { duration: 0.2 },
      },
    },
    exit: (directionValue: any) => {
      return {
        y: directionValue > 0 ? -200 : 200,
        opacity: 0,

        transition: {
          y: {
            type: 'spring',
            stiffness: 300,
            damping: 30,
          },
          opacity: { duration: 0.2 },
        },
      };
    },
  };

  const nextStep = () => {
    setDirection(1);
    if (index === paymentActivities.length - 1) {
      setTopIndex(0);
      setIndex(0);
    } else {
      setTopIndex((100 / paymentActivities.length) * (index + 1));
      setIndex(index + 1);
    }
  };
  const prevStep = () => {
    setDirection(-1);
    if (index === 0) {
      setTopIndex(
        (100 / paymentActivities.length) * (paymentActivities.length - 1)
      );
      setIndex(paymentActivities.length - 1);
    } else {
      setTopIndex(topIndex - 100 / paymentActivities.length);
      setIndex(index - 1);
    }
  };

  return (
    <section className='tw-my-6 tw-flex tw-flex-col tw-bg-[#FFFFFF] tw-px-4 sm:tw-px-[2rem] md:tw-flex-row '>
      <section
        className={`tw-relative tw-hidden tw-h-[350px] tw-w-[4px] tw-bg-[#F2F4F7] md:tw-flex`}
      >
        <section
          className={`tw-absolute tw-h-[100px] tw-w-[4px]  tw-bg-[#7936F4]`}
          style={{ top: `${topIndex}%` }}
        ></section>
      </section>

      <section className='tw-relative tw-ml-16 tw-hidden tw-h-[520px] tw-bg-[#ffffff] sm:tw-h-[450px] md:tw-flex lg:tw-h-[400px]'>
        <AnimatePresence initial={false} custom={direction} mode='popLayout'>
          <motion.section
            variants={variants}
            key={paymentActivities[index]?.title}
            animate='animate'
            initial='initial'
            exit='exit'
            custom={direction}
          >
            <motion.section className={` tw-grid tw-grid-cols-10`}>
              <motion.section
                className={`tw-col-span-10 tw-flex tw-flex-col md:tw-col-span-5 lg:tw-col-span-5`}
              >
                <motion.section className='tw-my-4'>
                  <motion.img
                    alt='featured icon'
                    src={paymentActivities[index]?.icon}
                    width={48}
                    height={48}
                  />
                </motion.section>
                <motion.section className='tw-my-4 tw-text-[24px] tw-font-fontBold tw-leading-[32px] tw-text-[#1C2433] md:tw-text-[36px] md:tw-leading-[44px]'>
                  {paymentActivities[index]?.title}
                </motion.section>
                <motion.section className='tw-my-4 tw-text-[16px] tw-leading-[24px] tw-text-[#677289]'>
                  {paymentActivities[index]?.description}
                </motion.section>
              </motion.section>

              <motion.section
                className={`tw-col-span-10  tw-flex tw-justify-center md:tw-col-span-5 md:tw-ml-10 lg:tw-col-span-5 `}
              >
                <motion.img
                  src={paymentActivities[index]?.image}
                  className='tw-h-[320px] tw-w-[400px]'
                  alt='payment activity'
                />
              </motion.section>
            </motion.section>
          </motion.section>
        </AnimatePresence>
        <section className='tw-absolute tw-bottom-0 tw-left-8 tw-mb-3 tw-flex'>
          <button
            onClick={prevStep}
            className='tw-rounded-full tw-p-2 tw-font-fontBold tw-text-[#FFFFFF]'
          >
            <Image
              alt='prev icon'
              src={`${router.basePath}/assets/images/LeftIcon.webp`}
              width={44}
              height={44}
            />
          </button>
          <button
            onClick={nextStep}
            className=' tw-rounded-full tw-p-2 tw-font-fontBold tw-text-[#FFFFFF]'
          >
            <Image
              alt='next icon'
              src={`${router.basePath}/assets/images/RightArrow.webp`}
              width={44}
              height={44}
            />
          </button>
        </section>
      </section>
      <section className='tw-flex'>
        <section className='tw-flex tw-h-[1px] tw-w-full tw-justify-between tw-bg-[#F2F4F7] md:tw-hidden'>
          <section
            className={`tw-h-[4px] tw-w-[25%]  tw-bg-[#7936F4]`}
            style={{ marginLeft: `${topIndex}%` }}
          ></section>
        </section>
        <section className='tw-ml-4 tw-flex md:tw-hidden'>
          <button
            onClick={prevStep}
            className=' tw-rounded-full tw-p-2 tw-font-fontBold tw-text-[#FFFFFF]'
          >
            <Image
              alt='prev icon'
              src={`${router.basePath}/assets/images/LeftIcon.webp`}
              width={44}
              height={44}
            />
          </button>
          <button
            onClick={nextStep}
            className=' tw-rounded-full tw-p-2 tw-font-fontBold tw-text-[#FFFFFF]'
          >
            <Image
              alt='next icon'
              src={`${router.basePath}/assets/images/RightArrow.webp`}
              width={44}
              height={44}
            />
          </button>
        </section>
      </section>
      <section className='tw-relative tw-mx-2 tw-flex tw-h-[650px] tw-flex-col tw-justify-start tw-bg-[#ffffff] md:tw-hidden'>
        <AnimatePresence initial={false} custom={direction} mode='popLayout'>
          <motion.section
            variants={xVariants}
            key={paymentActivities[index]?.title}
            animate='animate'
            initial='initial'
            exit='exit'
            custom={direction}
          >
            <motion.section className='tw-my-1'>
              <motion.img
                src={paymentActivities[index]?.icon}
                width={'48px'}
                height={'48px'}
                alt='featured icon'
              />
            </motion.section>
            <motion.section className='tw-my-4 tw-text-[36px] tw-font-fontBold tw-text-[#1C2433]'>
              {paymentActivities[index]?.title}
            </motion.section>
            <motion.section className='tw-my-4 tw-text-[16px] tw-text-[#677289]'>
              {paymentActivities[index]?.description}
            </motion.section>
          </motion.section>
        </AnimatePresence>

        <AnimatePresence initial={false} custom={direction} mode='popLayout'>
          <motion.img
            variants={xVariants}
            key={paymentActivities[index]?.title}
            animate='animate'
            initial='initial'
            exit='exit'
            alt='payment activity'
            custom={direction}
            src={paymentActivities[index]?.image}
            className='tw-mx-auto tw-h-[320px] tw-w-[400px]'
          />
        </AnimatePresence>
      </section>
    </section>
  );
};
export default NewPaymentActivites;
