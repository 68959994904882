import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

import FormattedText from './FormattedText';
const Features = () => {
  const router = useRouter();
  const featureItems = [
    {
      icon: `${router.basePath}/assets/images/MainIcon.webp`,
      image: `${router.basePath}/assets/images/NonProfits_and_fundRaisers.svg`,
      title: 'Nonprofits and Fundraisers',
      description:
        'FormPay helps nonprofits and fundraising organizations with its donation form feature. You can create donation forms in one click. Collect one-time or recurring donations. Create workflows to acquire, welcome, and retain your donors.',
    },
    {
      icon: `${router.basePath}/assets/images/Feature_Icon.webp`,
      image: `${router.basePath}/assets/images/Subscriptions_selling_business.svg`,
      title: 'Subscription-selling businesses',
      description:
        'With the subscription payment support, FormPay streamlines recurring payment collection. You can collect your subscription payments with Stripe or Razorpay. Sync its data to HubSpot for monitoring, create workflows to send payment reminders and more.',
    },
    {
      icon: `${router.basePath}/assets/images/Feature_Icon-1.webp`,
      image: `${router.basePath}/assets/images/Online_course_seller.svg`,
      title: 'Online course sellers',
      description:
        'Selling online courses on your website? FormPay is the right fit for you. Create and add your courses, PDFs, webinars as products. Add them into HubSpot payment forms. Shorten the sales cycle, add discounts codes, and directly sell your products through forms.',
    },
    {
      icon: `${router.basePath}/assets/images/Feature_Icon-2.webp`,
      image: `${router.basePath}/assets/images/Microbusinesses_with_small_teams.svg`,
      title: 'Microbusinesses with a small team',
      description:
        'If you’re a pro photographer, astrologer, or a small team with limited SKUs, FormPay will fit in like an amazing help. Create product or service-specific forms. Sell your services directly with them. Have a better track record of your sales.',
    },
  ];
  interface FeatureInterface {
    icon: string;
    image: string;
    title: string;
    description: string;
  }
  const ContentComponent = (item: FeatureInterface) => {
    return (
      <section
        className='tw-my-4 tw-px-4 md:tw-mx-16 md:tw-my-0'
        role='img'
        aria-label='feature image'
      >
        <Image
          tabIndex={0}
          alt='feature image'
          src={item?.image}
          width={342}
          height={228}
          className='tw-h-[228px] tw-w-full sm:tw-h-[198px] sm:tw-w-[297px]  md:tw-w-[350px] lg:tw-h-[320px] lg:tw-w-[480px]'
        />
      </section>
    );
  };
  const ImageComponent = (item: FeatureInterface) => {
    return (
      <section className='tw-flex tw-max-w-[500px] tw-flex-col lg:tw-mt-12'>
        <section className='tw-my-2' role='img' aria-label='feature icon'>
          <img
            tabIndex={0}
            alt='feature icon'
            src={item?.icon}
            width={48}
            height={48}
          />
        </section>
        <section
          tabIndex={0}
          className='tw-my-2 tw-text-[24px] tw-font-[600] tw-leading-[32px] tw-text-[#1c2433] lg:tw-text-[30px] lg:tw-leading-[38px]'
        >
          {item?.title}
        </section>
        <section
          tabIndex={0}
          className='tw-font-normal tw-my-2 tw-text-[16px] tw-leading-[24px] tw-text-[#677289] lg:tw-text-[18px] lg:tw-leading-[28px]'
        >
          {item?.description}
        </section>
      </section>
    );
  };

  return (
    <section className='tw-my-16'>
      <section
        tabIndex={0}
        className='tw-my-4 tw-flex tw-justify-center tw-text-normalText tw-font-fontBold tw-leading-[24px] tw-text-[#7936f4]'
      >
        Features
      </section>
      <section
        tabIndex={0}
        className='tw-my-4 tw-flex tw-justify-center tw-text-[24px] tw-font-fontBold tw-leading-[32px] tw-text-darkerGrey md:tw-text-[28px] md:tw-leading-[34px] lg:tw-text-veryLargeText lg:tw-leading-[44px]'
      >
        Who can use FormPay?
      </section>

      <FormattedText
        parentClassNames=''
        childClassNames='tw-text-[#677289] tw-my-4 tw-text-[18px] tw-leading-[28px] lg:tw-text-[20px] lg:tw-leading-[30px] tw-w-[358px] md:tw-w-[768px]'
      >
        {
          'Nonprofits, Subscription Business, Online Course-sellers, Event Business, Microbusinesses'
        }
      </FormattedText>

      <section className='tw-my-12 tw-flex tw-flex-col '>
        {featureItems.map((item, index) => (
          <section
            className={`tw-flex tw-flex-col tw-items-center tw-justify-center ${
              index % 2 === 0
                ? 'md:tw-flex-row lg:tw-flex-row'
                : 'md:tw-flex-row-reverse lg:tw-flex-row-reverse'
            } tw-my-8 tw-justify-center`}
            key={index}
          >
            <section className='tw-px-[2rem] md:tw-mx-16'>
              {ImageComponent(item)}
            </section>
            {ContentComponent(item)}
          </section>
        ))}
      </section>
    </section>
  );
};
export default Features;
