import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { useGradient } from '@/hooks/useGradient';

import FormattedText from './FormattedText';
const Others = () => {
  const router = useRouter();

  const gradient = useGradient();

  const otherItems = [
    {
      icon: `${router.basePath}/assets/images/Icon.webp`,
      title: 'Payment with HubSpot forms,links and quotes',
      description:
        'With FormPay you get multiple ways to collect payments in your CRM.',
    },
    {
      icon: `${router.basePath}/assets/images/globe.svg`,
      title: 'Collect payments anywhere in the world',
      description:
        'Don’t let your region hold your growth and collect payments anywhere.',
    },
    {
      icon: `${router.basePath}/assets/images/Payment.svg`,
      title: 'Recurring and one-time payment support',
      description:
        'Offer flexible payment options and make transactions convenient.',
    },
    {
      icon: `${router.basePath}/assets/images/Payment-1.svg`,
      title: 'Affordable and fits in your budget',
      description:
        'FormPay doesn’t drain your wallet. It comes at the price of a meal.',
    },
    {
      icon: `${router.basePath}/assets/images/check-square.svg`,
      title: 'On-demand customization',
      description:
        'You request, we deliver. We’re always ready to tweak our app for you.',
    },
    {
      icon: `${router.basePath}/assets/images/Dollar.svg`,
      title: 'Supports multiple currencies',
      description:
        'Let your customers pay in their own currency so they feel more connected.',
    },
  ];
  interface ImageComponentInterface {
    icon: string;
    title: string;
    description: string;
  }
  const ImageComponent = (item: ImageComponentInterface) => {
    return (
      <section className='tw-z-[99] tw-flex tw-h-auto tw-w-[400px]  tw-flex-col'>
        <section
          className='tw-mx-auto tw-my-2'
          role='img'
          aria-label='payment'
          tabIndex={0}
        >
          {' '}
          <Image alt='others' src={item?.icon} width={20} height={20} />
        </section>
        <section className='tw-z-[99] tw-mx-auto tw-my-2 tw-text-[18px] tw-font-fontMedium tw-leading-[28px] tw-text-[#FFFFFF] lg:tw-text-[20px] lg:tw-leading-[30px]'>
          <section className='tw-text-center ' tabIndex={0}>
            {item?.title}
          </section>
        </section>
        <section className='tw-font-normal tw-z-[99] tw-mx-auto tw-my-2 tw-text-[16px] tw-leading-[24px]  tw-text-[#ECEDEE]'>
          <section className='tw-text-center ' tabIndex={0}>
            {' '}
            {item?.description}
          </section>{' '}
        </section>
      </section>
    );
  };

  useEffect(() => {
    //@ts-ignore
    gradient.initGradient('#gradient-canvas');
  });

  return (
    <section className='tw-relative tw-bg-gradient-to-r tw-from-[#4D2A8D] tw-to-[#150C27] tw-px-[4rem] tw-py-[6rem] sm:tw-px-[6rem] sm:tw-py-[8rem]'>
      <canvas
        className='tw-absolute tw-inset-0 tw-z-[9] tw-opacity-[1]'
        id='gradient-canvas'
        data-js-darken-top
        data-transition-in
      ></canvas>
      <FormattedText
        parentClassNames=''
        childClassNames='tw-z-[99] tw-font-fontBold tw-mb-[1rem] tw-text-[24px] md:tw-text-[28px] lg:tw-text-[36px]  tw-text-[#FFFFFF] tw-leading-[32px] md:tw-leading-[34px] lg:tw-leading-[44px]'
      >
        {'Why FormPay Over Others?'}
      </FormattedText>
      <div className='tw-flex'></div>

      <section
        className={`tw-z-[99] tw-mx-[11px] tw-grid tw-w-full tw-max-w-[1024px] tw-grid-cols-12 tw-px-3 sm:tw-px-6 lg:tw-mt-[11px] lg:tw-max-w-[1200px] xl:tw-mx-auto`}
      >
        {otherItems.map((item, i) => (
          <section
            key={i}
            className={`tw-col-span-12 tw-mx-4 tw-my-8 tw-flex tw-justify-center md:tw-col-span-6 lg:tw-col-span-4`}
          >
            {ImageComponent(item)}
          </section>
        ))}
      </section>
    </section>
  );
};
export default Others;
