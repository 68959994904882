import React from 'react';
const Stats = () => {
  const statsData = [
    { content: <>8+</>, title: 'Gateways integrated (more to come)' },
    { content: <>100+</>, title: 'Businesses served' },
    { content: <>65000+</>, title: 'Products sold with FormPay' },
    { content: <>50000+</>, title: 'Customers helped' },
  ];

  return (
    <section className='tw-my-32'>
      <section
        tabIndex={0}
        className='tw-my-6 tw-text-center tw-text-[24px] tw-font-fontBold tw-leading-[32px] tw-text-[#1c2433] md:tw-text-[36px] md:tw-leading-[44px]'
      >
        We don’t just promise. We deliver.
      </section>
      <section
        tabIndex={0}
        className='tw-my-6 tw-mb-10 tw-text-center tw-text-[18px] tw-font-fontLight tw-leading-[28px] tw-text-[#677289] md:tw-text-[20px] md:tw-leading-[30px]'
      >
        And the numbers prove it.
      </section>
      <section className='tw-flex tw-flex-col tw-justify-center tw-py-3 md:tw-flex-row lg:tw-flex-row xl:tw-flex-row 2xl:tw-flex-row'>
        {statsData.map((item, i) => (
          <section
            key={i}
            className='tw-my-8 tw-flex tw-justify-center md:tw-mx-3 xl:tw-my-0'
          >
            <section className='tw-mr-8 tw-hidden tw-h-[9rem] tw-w-[2px] tw-bg-[#7936f4] lg:tw-flex'></section>
            <section
              tabIndex={0}
              className='tw-my-8 tw-flex tw-w-auto tw-flex-col tw-items-center tw-justify-start tw-text-[48px] tw-font-fontBold tw-leading-[60px] tw-text-[#7936f4]  sm:tw-text-[38px] sm:tw-leading-[50px] md:tw-my-0 md:tw-max-w-[300px] lg:tw-items-start lg:tw-text-left xl:tw-max-w-[350px] xl:tw-text-[48px] xl:tw-leading-[60px]'
            >
              {item?.content}

              <section className='tw-w-auto tw-text-center  tw-text-[18px] tw-font-fontMedium tw-leading-[28px] tw-text-[#677289] sm:tw-text-[16px] sm:tw-leading-[22px] lg:tw-max-w-[180px] lg:tw-text-start lg:tw-text-[18px] lg:tw-leading-[28px] xl:tw-max-w-[350px]'>
                {item?.title}
              </section>
            </section>
          </section>
        ))}
      </section>
    </section>
  );
};
export default Stats;
