import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
const Content = () => {
  const router = useRouter();

  return (
    <>
      <section className='tw-font-bolder tw-mt-[80px] tw-flex tw-flex-col tw-pt-12 lg:tw-pt-32'>
        <section className='tw-mx-auto tw-my-8'>
          <section
            tabIndex={0}
            className='tw-h-auto tw-w-[390px] tw-text-center tw-text-[34px] tw-font-fontMoreBold tw-leading-[42px] tw-tracking-[-0.03em] tw-text-blueDark sm:tw-w-[600px] md:tw-w-[700px] md:tw-text-[44px] md:tw-leading-[54px] lg:tw-w-[1024px] lg:tw-text-[52px] lg:tw-leading-[64px]'
          >
            All in one{' '}
            <span className='main-title-animated tw-text-[34px] tw-font-fontMoreBold tw-leading-[42px] tw-tracking-[-0.03em] tw-text-blueDark md:tw-text-[44px] md:tw-leading-[54px] lg:tw-text-[52px] lg:tw-leading-[64px]'>
              Payments Solution{' '}
            </span>
            for{' '}
            <span className='main-title-animated tw-text-[34px] tw-font-fontMoreBold tw-leading-[42px] tw-tracking-[-0.03em] tw-text-blueDark md:tw-text-[44px] md:tw-leading-[54px] lg:tw-text-[52px] lg:tw-leading-[64px]'>
              HubSpot.
            </span>{' '}
            <span className='tw-text-[34px] tw-font-fontMoreBold tw-leading-[42px] tw-tracking-[-0.03em] tw-text-blueDark md:tw-text-[44px] md:tw-leading-[54px] lg:tw-text-[52px] lg:tw-leading-[64px]'>
              Any gateway, Worldwide.
            </span>
          </section>
        </section>
        <section className='tw-mx-auto tw-my-8'>
          <p
            tabIndex={0}
            className='tw-h-auto tw-w-[390px] tw-text-center tw-text-largeText tw-font-fontLight tw-leading-[28px] tw-text-darkGrey sm:tw-w-[600px] md:tw-w-[700px] md:tw-leading-[26px] lg:tw-w-[1024px]'
          >
            Connect your favorite gateway with HubSpot using FormPay. Collect,
            track, manage and{' '}
            <span className='tw-text-largeText tw-font-fontLight tw-leading-[28px] tw-text-darkGrey md:tw-leading-[26px]'>
              automate your one-time or recurring payment data within HubSpot.
            </span>
          </p>
        </section>

        <section className='tw-mt-8 tw-flex tw-w-full tw-flex-col tw-items-center sm:tw-w-auto sm:tw-flex-row  sm:tw-justify-center'>
          <Link
            href='/onboarding'
            className='tw-my-4 tw-w-full tw-text-center sm:tw-my-0 sm:tw-w-auto sm:tw-text-start sm:tw-align-top'
          >
            <button
              tabIndex={0}
              className='tw-shadow-[0_1px_2px_rgba(16, 24, 40, 0.05)] tw-h-16 tw-w-[90%] tw-rounded-lg tw-border-[1px]  tw-bg-[#7936F4] tw-px-4  tw-py-2 tw-text-normalText tw-font-fontMedium tw-text-white sm:tw-mr-4  sm:tw-w-[128px]'
            >
              Try for Free
            </button>
          </Link>
          <button
            tabIndex={0}
            role='img'
            aria-label='schedule call'
            className='tw-shadow-[0_1px_2px_rgba(16, 24, 40, 0.05)] tw-my-4  tw-flex tw-h-16 tw-w-[90%] tw-items-center tw-justify-center tw-rounded-lg tw-border-[1px]  tw-border-solid tw-border-[#D0D5DD] tw-bg-white tw-px-4 tw-py-2 tw-font-fontMedium tw-text-white sm:tw-my-0 sm:tw-w-[184px]'
          >
            <Image
              src={`${router.basePath}/assets/images/PhoneIcon.webp`}
              className='tw-mr-[10px]'
              alt='Schedule Call'
              width={18}
              height={18}
            />
            <Link href='/schedule-meeting'>
              <span className='tw-text-normalText tw-font-fontMedium tw-text-[#3B424F]'>
                Schedule a Call
              </span>
            </Link>
          </button>
        </section>
      </section>
      <section
        className='tw-mx-auto tw-my-0 tw-flex tw-h-[300px] tw-bg-white tw-pt-12 md:tw-h-[466px]'
        style={{
          backgroundImage: `url(${router.basePath}/assets/images/BackgroundPattern.webp)`,

          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          justifyContent: 'center',
          backgroundPosition: 'center',
        }}
        role='img'
        aria-label='payment solution'
      >
        <img
          src={`${router.basePath}/assets/images/NewOne.webp`}
          alt='main content'
          className='tw-h-[210px] tw-w-[350px] md:tw-h-[342px] md:tw-w-[725px]'
        />
      </section>
    </>
  );
};
export default Content;
