import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

const AppFeatures = () => {
  const router = useRouter();
  const appFeatures = [
    'Form Submission Tracking',
    'Discount Coupons',
    'VAT Calculation',
    'HubSpot Deal Integration',
    'One-time & Subscription Payments',
    'Recurring Donations',
    'Product-specific Forms',
    'Transaction Reporting',
    'Donation Form',
    'Custom Fields for Automation',
    'Embed Code & Form Link',
    'Custom Form Styling',
    'Multilingual Support',
  ];

  return (
    <section className='tw-mx-[11px] tw-flex tw-max-w-[1024px] tw-flex-col lg:tw-mt-[11px]  lg:tw-max-w-[1200px] xl:tw-mx-auto'>
      <section
        tabIndex={0}
        className='tw-my-4 tw-mt-10 tw-flex tw-justify-start tw-text-[28px] tw-font-fontBold tw-leading-[34px] tw-text-darkerGrey sm:tw-px-6 md:tw-text-[36px] md:tw-leading-[44px]'
      >
        Features of FormPay
      </section>

      <section className='tw-my-4 tw-p-[2rem]'>
        <section className={`tw-grid tw-grid-cols-12`}>
          <section
            className={`tw-col-span-12 tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-col-span-4`}
          >
            <section
              tabIndex={0}
              className='tw-my-4 tw-flex tw-h-[28px] tw-w-[150px] tw-justify-start tw-rounded-[16px] tw-border-[1px] tw-border-successLight tw-bg-successLight tw-p-[4px_16px] tw-text-smallText tw-text-successDark'
            >
              <section className=' '>Free 7-day Trial</section>
            </section>
            <section>
              {appFeatures.map((item, i) => (
                <section
                  tabIndex={0}
                  className='tw-my-2 tw-flex tw-justify-start'
                  role='img'
                  aria-label={item}
                  key={i}
                >
                  <Image
                    src={`${router.basePath}/assets/images/GreenTick.webp`}
                    alt='feature present'
                    width={20}
                    height={20}
                    className='tw-h-[20px] tw-w-[20px]'
                  />
                  <section className='tw-ml-8'>{item}</section>
                </section>
              ))}
            </section>
            <section className='tw-flex sm:tw-justify-start md:tw-justify-start lg:tw-justify-start'>
              <Link
                href='/onboarding'
                target='_blank'
                className='tw-my-5 tw-w-full'
              >
                <button
                  tabIndex={0}
                  className='tw-font-medium tw-my-2 tw-flex tw-w-full tw-justify-center tw-rounded-[8px] tw-border tw-border-solid tw-border-formPay100 tw-bg-formPay100 tw-px-4 tw-py-2 tw-text-smallText tw-text-white tw-shadow-[0_1px_2px_rgba(16,24,40,0.05)] md:tw-w-72 lg:tw-my-0 lg:tw-w-96'
                >
                  Try Now{' '}
                  <span
                    className='tw-ml-2 tw-text-white'
                    role='img'
                    aria-label='try now'
                  >
                    <Image
                      src={`${router.basePath}/assets/images/WhiteArrow.webp`}
                      tabIndex={0}
                      alt='try now'
                      width={20}
                      height={20}
                      className='tw-h-[20px] tw-w-full'
                    />
                  </span>
                </button>
              </Link>
            </section>
          </section>
          <section
            className={`tw-col-span-12 tw-mt-6 md:tw-col-span-8 lg:tw-mt-0`}
            role='img'
            aria-label='macbook'
          >
            <img
              tabIndex={0}
              src={`${router.basePath}/assets/images/MacbookIcon.webp`}
              alt='macbook'
              className='tw-h-full tw-w-full sm:tw-h-[350px] md:tw-ml-16 md:tw-mt-40 md:tw-h-[400px] md:tw-w-[650px]'
            />
          </section>
        </section>
      </section>
    </section>
  );
};
export default AppFeatures;
