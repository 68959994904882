import Head from 'next/head';
import React from 'react';

import AppFeatures from '@/components/AppFeatures';
import Content from '@/components/Content';
import FAQ from '@/components/FAQ';
import Features from '@/components/Features';
import Footer from '@/components/Footer';
import FormattedText from '@/components/FormattedText';
import Gateways from '@/components/Gateways';
import Header from '@/components/Header';
import NewPaymentActivites from '@/components/NewPaymentActivities';
import NewReviews from '@/components/NewReviews';
import Others from '@/components/Others';
import Stats from '@/components/Stats';

const Home = () => {
  

  return (
    <>
      <Head>
        <title>FormPay</title>
        <meta
          name='description'
          content='HubSpot Payment integration to receive payments in HubSpot CRM'
          key='desc'
        />
        <meta
          name='google-site-verification'
          content='N6tVwXQIbZTwmv8ZVPAdanP0abYdasKm8XO_x46sg4M'
        />
      </Head>
      <section className='tw-h-[100%] tw-w-fit md:tw-w-[100%]'>
        <section className='tw-bg-purpleLight2'>
          <Header />
          <Content />
        </section>

        <Features />
        <Others />
        <Stats />
        <FormattedText
          childClassNames='tw-h-[1px] tw-w-[85vw] tw-bg-lightGrey4 tw-my-4'
          parentClassNames='tw-my-8'
        >
          {''}
        </FormattedText>
        <div className='tw-mx-[11px]  tw-max-w-[1024px] tw-px-3 sm:tw-px-6 lg:tw-mt-[11px] lg:tw-max-w-[1200px] xl:tw-mx-auto'>
          <Gateways />
        </div>

        <div className='tw-mx-[11px] tw-max-w-[1024px] tw-px-3 sm:tw-px-6 lg:tw-mt-[11px] lg:tw-max-w-[1200px]  xl:tw-mx-auto'>
          <section className='tw-mx-4 tw-mb-20 tw-flex tw-w-[26rem] tw-justify-start tw-rounded-[20px] tw-border-[1px] tw-bg-formPay10 tw-text-smallText tw-text-formPay100'>
            <section className='tw-mx-auto'>
              Streamline all your payment activities
            </section>
          </section>
          <NewPaymentActivites />
        </div>
        <div className='tw-mx-[11px] tw-max-w-[1024px] tw-px-3 sm:tw-px-6 lg:tw-mt-[11px] lg:tw-max-w-[1200px]  xl:tw-mx-auto'>
          <NewReviews />
        </div>
        <AppFeatures />
        <FAQ />

        <Footer />
      </section>
    </>
  );
};
export default Home;
